<template>
    <footer data-review="ddd" data-auto-load="false">
        <div class="inner">
            <aside id="bannerConsult">
                <button class="btn_default default" type="button">상담신청</button>            
            </aside>
            <div class="leftArea">
                <nav>
                    <button type="button" onclick="LAYER('policy_privacy')" style="color: #24a1ff">개인정보처리방침</button>
                    <i style="margin: 0 13px;">|</i>
                    <button type="button" onclick="LAYER('policy_usege')" >이용약관</button>
                    <i style="margin: 0 13px;">|</i>
                    <b class="">감수 <span style="font-weight:100;">세무법인 세종TSI</span></b>
                </nav>
                <address>
                    <p>
                        <em>피플라이프(주) 보험대리점(등록번호 제2004018025호) 대표이사 <span data-peoplelife="owner"></span></em><br>
                        <em>사업자등록번호: <span data-peoplelife="corpNumber"></span></em>
                        <em>(<span data-peoplelife="postNumber"></span>) <span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></em>
                    </p>
                    <p class="review" data-thispage="deliberation"></p>
                    <span>© 2021 매경경영지원본부. All rights reserved</span>
                </address>
            </div>
            <div class="rightArea">
                <h2><span></span></h2>
                <div class="link_info">
                    <a class="naver_blog press" href="https://blog.naver.com/mkbiz_kr" target="_blank"><img src="/img/common/ico/naver_blog.png" alt="네이버 블로그 바로가기"></a>
                    <div class="info">
                        <p>
                            <strong>평      일</strong><span>09:00~18:00</span>
                        </p>
                        <p>
                            <strong>상담문의</strong><span>1800 - 9440</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="toTop" data-json-path="/image/common/toTop_gray.png">위로</button>
    </footer>
</template>


<style lang="scss">

</style>



<script>
export default {}
</script>